import React from 'react';

function Login() {

  return (
    <>
      <p>Work In Progress </p>
    </>
  );
}

export default Login;
